import React, { useState, useEffect } from "react";
import { Select, Menu, MenuItem, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { nanoid } from "nanoid";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "./modal";

export const FilterContext = React.createContext(null);

function Filters(props) {
  const [showModal, setShowModal] = useState(props.showModal || false);
  const [actionType, setActionType] = useState("");
  const [attributeList, setAttributeList] = useState(props?.attributeData);
  const [showErrors, setShowErrors] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState("");
  const {
    options,
    customStyle,
    getFilterData,
    attributesValueURL,
    onClickFilter,
    onModalClose,
  } = props;

  useEffect(() => {
    setAttributeList([
      {
        attrId: nanoid(),
        values: [],
        attribute: "",
        relation: "",
        isValid: false,
      },
      ...props?.attributeData,
    ]);
  }, [props.attributeData]);

  useEffect(() => {
    if (props.showModal === false) {
      handleClose();
      setFilterName("");
    }
    setShowModal(props.showModal);
  }, [props.showModal]);

  const { Provider } = FilterContext;

  function onFilterChange(data) {
    const { attrData, elementIndex } = data;

    const attrList = [...attributeList];
    attrList[elementIndex] = attrData;
    setAttributeList(attrList);
  }

  function onAddFilterItem(attributeData, elementIndex) {
    if (attributeData?.isValid) {
      const attrList = [...attributeList];
      attrList[elementIndex] = attributeData;
      setShowErrors(false);
      setAttributeList([
        {
          attrId: nanoid(),
          values: [],
          attribute: "",
          relation: "",
          isValid: false,
        },
        ...attrList,
      ]);
    } else {
      setShowErrors(true);
    }
  }

  function deleteFilter(elementIndex) {
    const attrList = [...attributeList];
    attrList.splice(elementIndex, 1);
    setAttributeList(attrList);
  }

  function onClearAttributes(elementIndex) {
    const attrList = [...attributeList];

    attrList[0] = { ...attrList[0], relation: "", attribute: "", values: [] };

    setAttributeList(attrList);
  }

  function applyFilters(flag) {
    const attrList = [...attributeList];
    console.log("applyFilters", attrList)
    attrList.splice(0, 1);
    let errors = _.filter(attrList, { isValid: false });
    if (!attrList?.length || !filterName?.trim().length) {
      errors[0] = [{ relation: "", values: [], attribute: "", isValid: false }];
    }
    setShowErrors(errors?.length);
    getFilterData({
      attrList,
      actionType: flag,
      isValid: !errors.length,
      filterName,
    });
    if (!errors?.length) {
      // if(flag !== 'SAVE_APPLY'&& flag !== 'ONLY_APPLY') {
      //     onModalClose();
      // }
    }
  }

  //   function onModalClose() {
  //     setShowModal(false);
  //     setAttributeList([]);
  //     setFilterName('');
  //   }

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Provider
      value={{
        onFilterChange,
        attributeList,
        onAddFilterItem,
        deleteFilter,
        onClearAttributes,
        showErrors,
        attributesValueURL,
        actionType,
        applyFilters,
        filterName,
      }}
    >
      <FormControl
        size="small"
        variant="outlined"
        style={{
          marginTop: "0",
          marginBottom: "4px",
          minWidth: "170",
          height: "32px",
          width: "400px",
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          style={{ top: "-5px", height: "32px" }}
        >
          Filter
        </InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          labelId="filter-label"
          id="filter-setup"
          defaultValue="None"
          value={selectedFilter}
          renderValue={(value) => <label>{value}</label>}
          label="Filter"
          style={customStyle}
        >
          <MenuItem
            margin="dense"
            value="Add Filter"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 200,
              background: "#fff",
              padding: "0 8px",
              borderBottom: "1px solid #ccc",
            }}
            onClick={(event) => {
              setAttributeList([
                {
                  attrId: nanoid(),
                  values: [],
                  attribute: "",
                  relation: "",
                  isValid: false,
                  index: 0,
                },
              ]);
              // setShowModal(true);
              onClickFilter("", "add");
              setSelectedFilter("Add Filter");
              setActionType("ADD");
              event.stopPropagation();
            }}
          >
            <AddIcon /> Add Filter
          </MenuItem>

          {options?.map((option, index) => (
            <MenuItem style={{ padding: 0, width: 200 }} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: 200,
                  background: "#fff",
                  padding: "0 8px",
                  borderBottom: "1px solid #ccc",
                }}
                onClick={() => {
                  setSelectedFilter(option?.name);
                  onClickFilter(option?.name, "item");
                }}
              >
                <span
                  style={{
                    margin: 0,
                    marginLeft:"5px",
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {option?.name}
                </span>
                <div>
                  <IconButton aria-label="edit" style={{ padding: "0" }}>
                    <EditIcon
                      onClick={(event) => {
                        onClickFilter(option?.name, "edit");
                        // setShowModal(true);
                        setFilterName(option?.name);
                        setActionType("EDIT");
                        event.stopPropagation();
                      }}
                      style={{ color: "rgb(93 156 236)", marginRight: "5px" }}
                    />
                  </IconButton>
                  <IconButton aria-label="edit" style={{ padding: "0" }}>
                    <DeleteIcon
                      onClick={(event) => {
                        // onClickFilter(option?.name,'delete');
                        setOpenConfirmation(true);
                        setDeletedFilter(option?.name);
                        event.stopPropagation();
                      }}
                      style={{ color: "#939393", marginRight: "5px" }}
                    />
                  </IconButton>
                </div>
              </div>
            </MenuItem>
          ))}
          <MenuItem
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 200,
              background: "#fff",
              padding: "0 8px",
            }}
            value="None"
            onClick={() => {
              setSelectedFilter("None");
              onClickFilter("none");
            }}
          >
            None
          </MenuItem>
        </Select>
      </FormControl>
      {showModal && (
        <Modal
          showModal={showModal}
          onModalClose={(e) => {
            onModalClose();
            setShowModal(false);
            setFilterName("");
            handleClose();
          }}
          changeFilterName={setFilterName}
          filterName={filterName}
          showErrors={showErrors}
        />
      )}
      <Dialog
        open={openConfirmation}
        onClose={openConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to delete?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                onClickFilter(deletedFilter, "delete");
                handleClose();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Provider>
  );
}

export default Filters;
