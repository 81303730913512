import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "@bamboobox/b2logincheck";
import { LOGIN_URL, AUTH_URL } from "./Constant";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
