export const LOGIN_URL = window._env_.LOGIN_URL;
export const AUTH_URL = window._env_.AUTH_URL;
export const ATTRIBUTE_URL=window._env_.ATTRIBUTE_URL;
export const FILTER_URL=window._env_.FILTER_URL;
export const ICP_URL=window._env_.ICP_URL;
export const B2PROSPECT_URL=window._env_.B2PROSPECT_URL;
export const UNIFIED_URL=window._env_.UNIFIED_URL;
export const B2ENRICHMENT_URL=window._env_.B2ENRICHMENT_URL;
export const B2FRONTEND_PROSPECT_URL=window._env_.B2FRONTEND_PROSPECT_URL;
export const META_BASE_URL = window._env_.META_BASE_URL;
export const B2_WEBFORMFILL_FRONTEND = window._env_.B2_WEBFORMFILL_FRONTEND;
export const LICENSE_STRING  = window._env_.LICENSE_STRING ;
