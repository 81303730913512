import React, { useState, useEffect } from "react";
import { useAuthState } from "@bamboobox/b2logincheck";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Button, MenuItem, Select, FormControl } from "@material-ui/core/";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MuiAlert from "@material-ui/lab/Alert";
import Filters from "./filters/index";
import { ATTRIBUTE_URL } from "./Constant";
import {
  saveFilters,
  getFilterNames,
  getFilterDataByName,
  deleteFilters,
} from "./api";
import Header from "./Header";
import TotalAccount from "./totalAccount/TotalAccount";
import TaskAlert from "./tasksAlert/TaskAlert";
import ColumnChart from "./newAccount/ColumnChart";
import UItheme from "@bamboobox/b2-theme";
import { useParams } from 'react-router-dom';
import {DATE_FIELDS} from './filters/attributeitem/constants'

const ui = "account_view";
const mapper = {
  "Industry": ["companyPrimaryIndustryCategory", "ACCOUNT"],
  "Sub Industry": ["companySubIndustryCategory", "ACCOUNT"],
  "Company Revenue": ["companyBrandRevenueNumerical", "ACCOUNT"],
  "Account Classification": ["companyClassification", "ACCOUNT"],
  "Capillary Geography": ["crmAccountGeography", "ACCOUNT"],
  "Company Type": ["companyType", "ACCOUNT"],
  "Company Hierarchy": ["companyHierarchy", "ACCOUNT"],
  "Account Region": ["companyAccountRegion", "ACCOUNT"],
  "Number of Contacts": ["count_contacts", "ACCOUNT"],
  "Number of Stores": ["crmNumberOfStores", "ACCOUNT"],
  "Account Source": ["accountSource", "ACCOUNT"],
  "Company Name": ["companyName", "ACCOUNT"],
  "Company Country": ["companyCountry", "ACCOUNT"],
  "Company State": ["companyState", "ACCOUNT"],
  "Company City": ["companyCity", "ACCOUNT"],
  "Company Phone": ["companyPhone", "ACCOUNT"],
  "Brand Revenue Number": ["companyBrandRevenueNumerical", "ACCOUNT"],
  "Business Format": ["crmBusinessFormat", "ACCOUNT"],
  "Group Company Name": ["companyGroupName", "ACCOUNT"],
  "Parent Company Name": ["companyParent", "ACCOUNT"],
  "Group Company Website": ["companyGroupWebsite", "ACCOUNT"],
  "Parent Company Website": ["companyParentWebsite", "ACCOUNT"],

  "Contact Mobile": ["contactMobile", "CONTACT"],
  "Contact Phone": ["contactPhone", "CONTACT"],

  "Field Sales": ["crmFSExecutive", "ACCOUNT"],
  "Inside Sales": ["crmISExecutive", "ACCOUNT"],

  "Account Creation Date": ["createddateTime", "ACCOUNT"],
  "Account Modification Date": ["modifieddateTime", "ACCOUNT"],
  "Contact Creation Date": ["createddateTime", "CONTACT"],
  "Contact Modification Date": ["modifieddateTime", "CONTACT"],
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 170,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AccountSummaryReport = () => {

  let { id } = useParams();

  const [attributeData, setAttributeData] = useState([]);
  const [body, setBody] = useState([]);
  const [unModifiedFilterBody, setUnModifiedFilterBody] = useState(id ? JSON.parse(unescape(id)) : []);
  const [chipData, setChipData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [action, setAction] = useState("");
  const [question, setQuestion] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [filterNamesArr, setFilterNamesArr] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const classes = useStyles();

  useEffect(() => {
    getFilterNames(tenantId, ui).then((json) => {
      setFilterOptions(json.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filtersArr = filterOptions.map((option) => {
      return option?.name;
    });
    setFilterNamesArr(filtersArr);
  }, [filterOptions]);

  useEffect(() => {
    applyFilters(id ? JSON.parse(unescape(id)) : []);
  }, [])

  function applyFilters(attrList) {
    setUnModifiedFilterBody(attrList)
    const attributeArray = attrList.map((object) => {
      const obj = { ...object };
      obj.attribute = mapper[object.attribute][0];
      obj.type = mapper[object.attribute][1];
      delete obj.isValid;
      delete obj.index;
      return obj;
    });
    setBody(attributeArray);
    setShowModal(false);
    const chipArray = attrList.map((element, index) => {
            
      if (DATE_FIELDS.includes(element.attribute)){
          let fromDate = new Date(element.values[0])
          let toDate = new Date(element.values[1])

          if (element.relation.toLowerCase() === "Is".toLowerCase()){
              
              return {
                  key: element.attrId,
                  label: `${element.attribute} (${fromDate.getDate()}-${(fromDate.getMonth()+1)}-${fromDate.getFullYear()})`,
              };

          } else {

          
          return {
              key: element.attrId,
              label: `${element.attribute}(${fromDate.getDate()}-${(fromDate.getMonth()+1)}-${fromDate.getFullYear()} to ${toDate.getDate()}-${(toDate.getMonth()+1)}-${toDate.getFullYear()})`,
          };
      }
      } else if (element.relation.toLowerCase() === "Is Empty".toLowerCase()) {
          const valuesArr = "Is Empty";
          return {
              key: element.attrId,
              label: `${element.attribute}(${valuesArr})`,
          };
      }
      const valuesArr = element.values.join(",");
      return {
          key: element.attrId,
          label: `${element.attribute}(${valuesArr})`,
      };
  });
    setChipData(chipArray);
    setFilterName("");
  }

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  function saveData(mode = "save", attrList, filterName) {
    saveFilters(mode, attrList, filterName, tenantId, ui).then((json) => {
      getFilterNames(tenantId, ui).then((res) => {
        setFilterOptions(res.data);
      });
      if (json?.success) {
        setSeverity("success");
        if (mode === "save") {
          applyFilters(attrList);
        } else {
          setShowModal(false);
          setFilterName("");
        }
      } else {
        setSeverity("error");
      }
      setMessage(json?.message);
      setOpenSnackbar(true);
    });
  }

  return (
    <MuiThemeProvider theme={UItheme}>
      <div className="page" >
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <Filters
            getFilterData={(data) => {
              setAction(data.actionType);
              if (data.isValid) {
                setAttributeData(data.attrList);
                setFilterName(data.filterName);

                if (data.actionType === "APPLY") {
                  setQuestion("Do you want to Apply?");
                  setOpenConfirmation(true);
                } else if (data.actionType === "SAVE") {
                  setQuestion("Do you want to Save?");
                  setOpenConfirmation(true);
                } else {
                  if (data.actionType === "SAVE_APPLY") {
                    if (filterNamesArr.includes(data.filterName)) {
                      setMessage("FilterName already exists!");
                      setSeverity("error");
                      setOpenSnackbar(true);
                    } else {
                      setQuestion("Do you want to Save and Apply?");
                      setOpenConfirmation(true);
                    }
                  } else {
                    setQuestion("Do you want to Apply?");
                    setOpenConfirmation(true);
                  }
                }
              } else {
                if (!data?.filterName) {
                  setMessage("Enter FilterName!");
                  setSeverity("error");
                  setOpenSnackbar(true);
                }
              }
            }}
            customStyle={{ width: 150, background: "#fff" }}
            onClickFilter={(filter, type) => {
              if (filter !== "none") {
                if (type === "edit") {
                  console.log(type);
                  getFilterDataByName(filter, tenantId, ui).then((json) => {
                    setAttributeData(json);
                    setShowModal(true);
                  });
                } else if (type === "delete") {
                  console.log("delete");
                  deleteFilters(filter, tenantId, ui)
                    .then((res) => {
                      getFilterNames(tenantId, ui)
                        .then((json) => {
                          setFilterOptions(json.data);
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                } else if (type === "add") {
                  setShowModal(true);
                } else {
                  getFilterDataByName(filter, tenantId, ui).then((json) => {
                    applyFilters(json);
                  });
                }
              } else {
                setBody([]);
                setChipData([]);
              }
            }}
            onModalClose={(e) => {
              setShowModal(false);
              setAttributeData([]);
              setFilterName("");
            }}
            showModal={showModal}
            attributeData={attributeData}
            options={filterOptions}
            attributesValueURL={`${ATTRIBUTE_URL}/getattribute?tenantId=${tenantId}`}

          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "inherit",
            alignItems: "center",
          }}
        >
          {chipData.map((data, index) => {
            return (
              <div key={data.key} style={{ margin: "1px" }}>
                <Chip
                  style={{ margin: "10px" }}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  label={data.label}
                  onDelete={handleDelete(data)}
                />
              </div>
            );
          })}
          <Dialog
            open={openConfirmation}
            onClose={openConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: "10%" }}
                  onClick={(e) => {
                    setOpenConfirmation(false);
                    setShowModal(false);
                    setFilterName("");
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10%" }}
                  onClick={(e) => {
                    setOpenConfirmation(false);
                    if (action === "SAVE") {
                      saveData("edit", attributeData, filterName);
                    } else if (action === "SAVE_APPLY") {
                      saveData("save", attributeData, filterName);
                    } else {
                      applyFilters(attributeData);
                    }
                    // setShowModal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            onClose={closeSnackbar}
          >
            <Alert onClose={closeSnackbar} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        </div>

        <TaskAlert requestBody={unModifiedFilterBody} />
        <TotalAccount requestBody={body} filterBody={unModifiedFilterBody} />
        <ColumnChart requestBody={body} filterBody={unModifiedFilterBody}/>
        {/* {(!userDetails.user.superUser && (userDetails.user.department.trim().toLowerCase() === "IS Executive".trim().toLowerCase() || userDetails.user.department.trim().toLowerCase() === "FS Executive".trim().toLowerCase()))?null:<ColumnChart requestBody={body} />} */}

      </div>
    </MuiThemeProvider>
  );
};

export default AccountSummaryReport;
