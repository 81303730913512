import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Divider,
  TextField,
  DialogContent,
  IconButton,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import AttributeList from "../attributelist";
import Footer from "../footer";

function Modal({
  showModal,
  onModalClose,
  changeFilterName,
  filterName,
  showErrors,
}) {
  return (
    <Dialog
      maxWidth="lg"
      open={showModal}
      onClose={onModalClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
    >
      <DialogContent
        id="customized-dialog-title"
        draggable
        style={{ minWidth: 700, position: "relative" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: 600,
            marginBottom: "6px",
            height: "fit-content",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <p>Filter Name</p>
            <TextField
              size="small"
              style={{
                padding: 5,
                width: 200,

                margin: "0 0 0 10",
                borderRadius: 5,
                borderWidth: 1,
                background: "#fff",
              }}
              variant="outlined"
              value={filterName}
              id="filter-name-header"
              error={showErrors && !filterName}
              onInput={(e) => {
                changeFilterName(e.target.value);
              }}
            />
          </div>
          <Tooltip disableFocusListener title="Close">
            <IconButton aria-label="close" style={{ padding: 0 }}>
              <CloseIcon onClick={onModalClose} />
            </IconButton>
          </Tooltip>
        </div>

        <Divider />
        <AttributeList />
        <Footer />
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(Modal);
