import React from 'react';
import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


function DateRangePickerComponent(props) {

    var threeWeeksOldDate = new Date();
    threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [oldDate, setOldDate] = React.useState(threeWeeksOldDate);

    const [fromDateMax, setFromDateMax] = React.useState(new Date());
    const [fromDateMin, setFromDateMin] = React.useState(undefined);
    const [toDateMax, setToDateMax] = React.useState(new Date());
    const [toDateMin, setToDateMin] = React.useState(undefined);

    let disabled = props?.disabled || false;

    const handleDateFromChange = (date, value) => {
        let tempDate = new Date(value);
        setToDateMin(tempDate.setDate(tempDate.getDate() + 1));
        setOldDate(new Date(value));
        var fromDateValue = new Date(value)
        fromDateValue.setUTCDate(fromDateValue.getDate())
        fromDateValue.setUTCHours(0, 0, 0, 0)
        var tempCurrentDate = new Date(currentDate)
        tempCurrentDate.setUTCDate(tempCurrentDate.getDate())
        tempCurrentDate.setUTCHours(0, 0, 0, 0)
        props.onAttributeChange([fromDateValue.toISOString(), (tempCurrentDate).toISOString()], "values")
        console.log("handleDateFromChange ", value, new Date(value), tempDate, toDateMin);
    };

    const handleDateToChange = (date, value) => {

        let tempDate = new Date(value);
        setFromDateMax(tempDate.setDate(tempDate.getDate() - 1));
        setCurrentDate(new Date(value));
        var toDateValue = new Date(value)
        toDateValue.setUTCDate(toDateValue.getDate())
        toDateValue.setUTCHours(0, 0, 0, 0)
        var tempOldDate = new Date(oldDate)
        tempOldDate.setUTCDate(tempOldDate.getDate())
        tempOldDate.setUTCHours(0, 0, 0, 0)
        props.onAttributeChange([tempOldDate.toISOString(), toDateValue.toISOString()], "values")
        console.log("handleDateToChange ", value, new Date(value), tempDate, fromDateMin);

    };

    React.useEffect(() => {
        props.onAttributeChange([oldDate.toISOString(), currentDate.toISOString()], "values")
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disabled={disabled}
                    minDate={fromDateMin}
                    maxDate={fromDateMax}
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    style={{ width: '100px' }}
                    value={oldDate}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <ArrowRightAltIcon />
                <KeyboardDatePicker
                    disableToolbar
                    disabled={disabled}
                    minDate={toDateMin}
                    maxDate={toDateMax}
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    style={{ width: '100px' }}
                    value={currentDate}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default DateRangePickerComponent;