import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControl from "@material-ui/core/FormControl";
import { IconButton } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import "./styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilterContext } from "../index";
import { CATEGORIES, NUMERIC_FIELDS, DATE_FIELDS } from "./constants";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { B2PROSPECT_URL, META_BASE_URL } from "../../Constant";
import DateRangePickerComponent from "../customComponents/DateRangePickerComponent";
import SingleDatePickerComponent from "../customComponents/SingleDatePickerComponent";

// import "./styles.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CANCEL_SVG = (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#dc1e1e" transform="translate(5 5)">
      <path d="m.5 10.5 10-10" />
      <path d="m10.5 10.5-10-10z" />
    </g>
  </svg>
);
const SAVE_SVG = (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.5 5.5 3 3 8.028-8"
      fill="none"
      stroke="#4caf50"
      transform="translate(5 6)"
    />
  </svg>
);

const defaultOption = [
  { title: 'Default Option' },
];


function AttributeItem(props) {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [relation, setRelation] = useState([]);
  // const [attributeData, setAttribute] = useState(props.attributeData)
  const [attributeValues, setAttributeValues] = useState([]);
  const [searchAttributeValues, setSearchAttributeValues] = useState("");
  const [showFirstIndexErrors, setShowFirstIndexErrors] = useState(false);
  const [valueType, setValueType] = useState({
    type: "number",
    placeholder: "0000",
  });
  const { elementIndex, attributeId, attributeData } = props;

  let intialValueAttribute = CATEGORIES.find(obj => {
    return obj.title === attributeData?.attribute
  }) || {};
  const [typedValue, setTypedValue] = useState(intialValueAttribute);
  console.log("filter props", props)

  // useEffect(() => {
  //   setAttribute(props?.attributeData);
  // }, [props?.attributeData]);

  const {
    onFilterChange,
    onAddFilterItem,
    deleteFilter,
    onClearAttributes,
    showErrors,
    attributesValueURL,
  } = useContext(FilterContext);

  function validateData(data) {
    const { attribute, relation, values } = data;
    const attr = { ...data };

    if (relation && relation === "Is Empty" && attribute) {
      attr.isValid = true;
    } else if (!attribute || !relation || !values.length) {
      
      attr.isValid = false;
    } else {
      attr.isValid = true;
      // setIsValid(true);
    }

    
    return attr;
  }

  const defaultProps = {
    options: defaultOption,
    getOptionLabel: (option) => option.title,
  };

  function onAttributeChange(value, key) {
    const attrData = { ...attributeData };
    console.log(value)
    console.log(key)
    console.log(attrData)

      attrData[key] = value;

    if (key === "attribute") {
      fetchRelationType(value);
      attrData["relation"] = "";
    }



    if (key !== "values") {
      attrData["values"] = [];
    }

    onFilterChange({ attrData: validateData(attrData), elementIndex });
  }

  function fetchAttributeValues() {
    if (attributeData?.attribute === 'Company Country') {
      fetch(B2PROSPECT_URL + '/b2pm/metadata/all?tenantId=' + tenantId, { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          var country = json.find(obj => {
            return obj.name === 'country'
          }
          )
          let countryList = []

          country.values.forEach(place => {
            countryList.push(place.value.slice());
          })
          if (countryList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });
            setAttributeValues(countryList);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Country Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Capillary Geography') {
      fetch(B2PROSPECT_URL + '/b2pm/metadata/all?tenantId=' + tenantId, { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          var capillaryGeo = json.find(obj => {
            return obj.name === 'geography'
          }
          )
          let capillaryGeographyList = []

          capillaryGeo.values.forEach(place => {
            capillaryGeographyList.push(place.value.slice());
          })
          console.log(capillaryGeographyList)
          if (capillaryGeographyList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });
            setAttributeValues(capillaryGeographyList);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Capilary Geography Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Business Format') {
      fetch(B2PROSPECT_URL + '/b2pm/metadata/all?tenantId=' + tenantId, { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          var businessFormat = json.find(obj => {
            return obj.name === 'businessFormat'
          }
          )
          let businessFormatList = []

          businessFormat.values.forEach(format => {
            businessFormatList.push(format.value.slice());
          })
          console.log(businessFormatList)
          if (businessFormatList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });
            setAttributeValues(businessFormatList);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Buisness Format Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Account Region') {
      fetch(META_BASE_URL + '/attribute-setup/get-all-child-attributes?tenantId=' + tenantId + '&&parent=Geography&&child=Account%20Region', { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          let regionList = []

          json.forEach(region => {
            regionList.push(region.child_value.slice());
          })
          if (regionList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });
            setAttributeValues(regionList.filter((value, index) => regionList.indexOf(value) === index));
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Acc Region Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Sub Industry') {
      fetch(META_BASE_URL + '/attribute-setup/get-all-child-attributes?tenantId=' + tenantId + '&&parent=Industry&&child=Sub Industry', { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          let subIndustryList = []

          json.forEach(subIndustry => {
            subIndustryList.push(subIndustry.child_value.slice());
          })
          if (subIndustryList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });

            setAttributeValues(subIndustryList.filter((value, index) => subIndustryList.indexOf(value) === index));
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Sub Industry Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Field Sales') {
      fetch(`${B2PROSPECT_URL}/b2pm/metadata/userbydepartment?tenantId=${tenantId}&department=FS Executive`, { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          let fieldSalesList = []

          json.forEach(fieldSales => {
            fieldSalesList.push(fieldSales.name.slice());
          })
          if (fieldSalesList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });

            setAttributeValues(fieldSalesList);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in Field Sales Value", e);
          setAttributeValues([]);
        })
    }
    else if (attributeData?.attribute === 'Inside Sales') {
      fetch(`${B2PROSPECT_URL}/b2pm/metadata/userbydepartment?tenantId=${tenantId}&department=IS Executive`, { credentials: 'include' })
        .then((result) => result.json())
        .then((json) => {
          let insideSalesList = []

          json.forEach(insideSales => {
            insideSalesList.push(insideSales.name.slice());
          })
          if (insideSalesList?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });

            setAttributeValues(insideSalesList);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in IS Value", e);
          setAttributeValues([]);
        })
    }
    else {
      fetch(
        `${attributesValueURL}&&parentattribute=${encodeURIComponent(
          attributeData?.attribute
        )}`,
        { credentials: "include" }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json?.length > 0) {
            setValueType({ type: "number", placeholder: "0000" });
            setAttributeValues(json);
          } else {
            setValueType({ type: "text", placeholder: "Enter" });
          }
        })
        .catch(e => {
          console.log("Error in  Value", e);
          setAttributeValues([]);
        })
    }

  }


  function fetchRelationType(value) {
    if (NUMERIC_FIELDS.includes(value)) {
      setRelation(["isEqual (=)", "greater than(>)", "less than(<)", "Is Empty"]);
      setValueType({ type: "number", placeholder: "0000" });
    } else {
      if (DATE_FIELDS.includes(value))
        setRelation(["Is", "Between", "Is Empty"])
      else
        setRelation(["includes", "Is Empty"]);
    }
  }

  function shouldShowErrors(key) {
    return (
      (showFirstIndexErrors && showErrors && !attributeData[key]?.length) ||
      (elementIndex !== 0 && showErrors && !attributeData[key]?.length)
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
        key={attributeData?.attrId}
      >
       <Autocomplete
          size="small"
          disableClearable
          value={typedValue}
          onChange={(event, newValue) => {
            console.log(`auto %c${JSON.stringify(newValue)}`, 'color:red')
            
            onAttributeChange(newValue.title, "attribute");
            setTypedValue(newValue)
          }}
          // inputValue={attributeData?.attribute}
          // filterOptions={x => x}
          // onInputChange={(event, newInputValue) => {
          //   console.log(`%c${newInputValue}`, 'color:blue')
          //   onAttributeChange(newInputValue, "attribute");
          // }}
          id="controllable-states-demo"
          options={CATEGORIES}
          getOptionLabel={(option) => option.title}
          groupBy={(option) => option.groupName}
          //defaultValue={attributeData?.attribute}
          style={{ width: 150 }}
          renderInput={(params) => (
            <TextField
              error={shouldShowErrors("attribute")}
              {...params}
              placeholder="Attributes"
              variant="outlined"
            />
          )}
        />
        <FormControl
          size="small"
          variant="outlined"
          style={{ marginLeft: "0", minWidth: 150 }}
        >
          {/* <InputLabel id="demo-simple-select-label">Relation</InputLabel> */}
          <Select
            size="sm"
            options={relation}
            value={attributeData?.relation || " "}
            style={{ width: 150 }}
            error={shouldShowErrors("relation")}
            renderValue={(value) => <label>{value}</label>}
          >
            {relation.map((data) => (
              <MenuItem
                value={data}
                onClick={() => {
                  if (data === "includes") {
                    fetchAttributeValues();
                  }
                  onAttributeChange(data, "relation");
                }}
              >
                {data}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {attributeData?.relation === "Is Empty" ?
          <Autocomplete
            size="small"
            {...defaultProps}
            id="disabled"
            disabled
            style={{ width: 150, marginBottom: 0 }}

            renderInput={(params) => (
              <TextField error={false} {...params} variant="outlined" />
            )}
          /> :
          attributeData?.relation === "includes" &&
            valueType?.type !== "text" ? (
            <Autocomplete
              size="small"
              disableClearable
              disableCloseOnSelect
              onChange={(event, newValue) => {
                onAttributeChange(newValue, "values");
              }}
              value={attributeData?.values}
              inputValue={searchAttributeValues}
              onInputChange={(event, newInputValue) => {
                setSearchAttributeValues(newInputValue);
              }}
              id="controllable-states-demo"
              options={attributeValues}
              style={{ width: 150, marginBottom: 0 }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </React.Fragment>
              )}
              multiple
              renderInput={(params) => (
                <TextField error={false} {...params} variant="outlined" />
              )}
            />
          ) : (
            attributeData?.relation === "Is" || attributeData?.relation === "Between" ?
              attributeData?.relation === "Is" ?
                <SingleDatePickerComponent onAttributeChange={onAttributeChange} /> : <DateRangePickerComponent onAttributeChange={onAttributeChange} /> :
              <input
                margin="dense"
                style={{
                  padding: 10,
                  width: 150,
                  height: "32px",
                  marginTop: "8px",
                  border: "1px solid #ccc",
                  borderColor: shouldShowErrors("values") ? "red" : "#ccc",
                }}
                type={valueType?.type}
                value={attributeData?.values[0]}
                onChange={(event) => {
                  onAttributeChange(
                    event?.target?.value ? [event?.target?.value] : [],
                    "values"
                  );
                }}
                placeholder={valueType?.placeholder}
              />
          )}

        <div style={{ width: "100px", marginTop: "8px", height: "32px" }}>
          {elementIndex === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "32px",
              }}
            >
              <Tooltip disableFocusListener title="Clear">
                <IconButton aria-label="clear" style={{ padding: "0" }}>
                  <CancelIcon
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onClearAttributes(elementIndex);
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip disableFocusListener title="Save">
                <IconButton aria-label="Save" style={{ padding: "0" }}>
                  <CheckCircleIcon
                    style={{
                      color: "green",
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowFirstIndexErrors(
                        elementIndex === 0 && !attributeData?.isValid
                      );
                      onAddFilterItem(
                        validateData(attributeData),
                        elementIndex
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "32px",
              }}
            >
              <Tooltip disableFocusListener title="delete">
                <IconButton aria-label="delete">
                  <DeleteIcon
                    onClick={(event) => {
                      deleteFilter(elementIndex);
                    }}
                    style={{
                      color: "#939393",
                      // marginRight: "5px",
                      cursor: "pointer",
                      padding: 0,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AttributeItem;
