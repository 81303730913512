import React from 'react';
import "./Header.css";
import Typography from '@material-ui/core/Typography';

const Header = () => {

    return (
        <div className="header-container">
            <div className="back-header-container">
                <Typography variant="h6">
                 Data Executive Report
                </Typography>
            </div>
        </div>
    )
}

export default Header
