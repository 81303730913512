import React from 'react';
import './TaskBar.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LOGIN_URL, B2ENRICHMENT_URL, B2FRONTEND_PROSPECT_URL, B2_WEBFORMFILL_FRONTEND } from "../Constant";
import theme from '@bamboobox/b2-theme';
import {useState} from 'react'
const TaskBar = (props) => {

    // var accWithoutContUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Number%20of%20Contacts%22%2C%22relation%22%3A%22less%20than%28%3C%29%22%2C%22values%22%3A%5B%221%22%5D%7D%5D`
    // var accWithLessThanTwoContUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Number%20of%20Contacts%22%2C%22relation%22%3A%22less%20than%28%3C%29%22%2C%22values%22%3A%5B%222%22%5D%7D%5D`
    // var contWithoutPhnUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Mobile%22%2C%22relation%22%3A%22Is%20Empty%22%2C%22values%22%3A%5B%22%22%5D%7D%5D`

    const [accountWithoutContact, setAccountWithoutContact] = React.useState('')
    const [accountWithLessThan2Contact, setAccountWithLessThan2Contact] = React.useState('')
    const [contactWithoutPhone, setContactWithoutPhone] = React.useState('')
    const createUrls = () => {
        var filterBodyForAccountWithoutContact = [{
            "attribute": "Number of Contacts",
            "relation": "less than(<)",
            "values": ['1']
        }]

        var filterBodyForAccountWithLessThan2Contact = [{
            "attribute": "Number of Contacts",
            "relation": "less than(<)",
            "values": ["2"]
        }]

        var filterBodyForContactWithoutPhone = [{
            "attribute": "Contact Mobile",
            "relation": "Is Empty",
            "values": []
        }]

        console.log("filter Body", props.reqBody, Array.isArray(props?.reqBody), props?.reqBody.length, props?.reqBody.length!==0)

        // if (!(Array.isArray(props?.reqBody) && !props?.reqBody.length  && !props?.reqBody.length===0)) {
        if (Array.isArray(props?.reqBody) && props?.reqBody.length  && props?.reqBody.length!==0) {
            let temp = props.reqBody;
            console.log("filter Body inside if condition",temp)
            for (let i=0; i < props.reqBody.length; i++) {
                let reqBody = props.reqBody[i]
                filterBodyForAccountWithoutContact.push(reqBody)
                filterBodyForAccountWithLessThan2Contact.push(reqBody)
                filterBodyForContactWithoutPhone.push(reqBody)
            }
        }

        setAccountWithoutContact(`${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterBodyForAccountWithoutContact))}/account_management/${escape(JSON.stringify(props.reqBody))}`)
        setAccountWithLessThan2Contact(`${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterBodyForAccountWithLessThan2Contact))}/account_management/${escape(JSON.stringify(props.reqBody))}`)
        setContactWithoutPhone(`${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterBodyForContactWithoutPhone))}/account_management/${escape(JSON.stringify(props.reqBody))}`)
    }

    React.useEffect(() => {
        createUrls()
    }, [props.reqBody])

    var renderTaskBar = () => {
        return (
            <div className='task-bar'>
                <div className='task-bar-content'>
                    <div className='alert-text'>
                        {props.task.label}
                    </div>
                    <div className='alert-count-container' >
                        <div className='alert-count'>
                            <span style={{ color: "rgb(93, 156, 236)" }}> {props.task.value}</span>
                            <ArrowForwardIosIcon style={{ fontSize: '20px' }} />
                        </div>
                    </div>
                </div>
            </div>)
    }
    if (props.task.label === "Webform Leads to proccess") {
        return (
            <a href={`${B2_WEBFORMFILL_FRONTEND}/#/?filter=${escape(JSON.stringify(props.reqBody))}&acc=asm`}>
                {renderTaskBar()}
            </a>
        )
    } else if (props.task.label === "Accounts to be Enriched") {
        return (
            <a href={`${B2ENRICHMENT_URL}/#/account?filter=${escape(JSON.stringify(props.reqBody))}&acc=asm`} >
                {renderTaskBar()}
            </a>
        )
    } else if (props.task.label === "Contacts to be Enriched") {
        return (
            <a href={`${B2ENRICHMENT_URL}/#/contact?filter=${escape(JSON.stringify(props.reqBody))}&acc=asm`}  >
                {renderTaskBar()}
            </a>
        )
    } else if (props.task.label === "Accounts without Contact") {
        return (
            <a href={accountWithoutContact}  >
                {renderTaskBar()}
            </a>
        )
    } else if (props.task.label === "Contacts without Mobile") {
        return (
            <a href={contactWithoutPhone} >
                {renderTaskBar()}
            </a>
        )
    } else if (props.task.label === "Accounts with less than 2 contacts") {
        return (
            <a href={accountWithLessThan2Contact}  >
                {renderTaskBar()}
            </a>
        )
    } else {
        return (
            renderTaskBar()
        )
    }
}

export default TaskBar
