import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {changeFromToDate} from "./../dateTimeFunctionRepository"
import moment from "moment"



const useStyles = makeStyles((theme) => ({
    toggleContainer: {
        backgroundColor: theme.palette.primary.background,
        height: "38px",
        margin: theme.spacing(2, 0),
        borderRadius: "4px",
        border: `1px solid ${theme.palette.primary.light}`
    },
    root: {
        selected: {
            backgroundColor: theme.palette.primary.light
        }
    }
}));

export default function FrequencyToggler(props) {

    console.log("FrequencyToggler", new Date(props?.fromDate).toISOString(), new Date(props?.toDate).toISOString())

    const [frequency, setFrequency] = React.useState("Weekly");
    const [position, setPosition] = React.useState("left")

    const [disableMonthly, setDisableMonthly] = React.useState(true)
    const [disableQuarterly, setDisableQuarterly] = React.useState(true)


    const handleFrequencyToggleDisable = () => {

        console.log("handleFrequencyToggleDisable just checking useffect", props?.fromDate, props?.toDate);

        let [fromDateMonthly, toDateMonthly] = changeFromToDate(props.fromDate, props.toDate, "Monthly");
        let [fromDateQuarterly, toDateQuarterly] = changeFromToDate(props.fromDate, props.toDate, "Quarterly");

        console.log("handleFrequencyToggleDisable before momentizing", fromDateMonthly, toDateMonthly, fromDateQuarterly, toDateQuarterly, props?.fromDate, props?.toDate);

        fromDateMonthly = moment(new Date(fromDateMonthly))
        toDateMonthly = moment(new Date(toDateMonthly))
        fromDateQuarterly = moment(new Date(fromDateQuarterly))
        toDateQuarterly = moment(new Date(toDateQuarterly))

        console.log("handleFrequencyToggleDisable", fromDateMonthly.toISOString(), toDateMonthly.toISOString(), fromDateQuarterly.toISOString(), toDateQuarterly.toISOString());

        if (props && props?.fromDate && props?.toDate && fromDateMonthly && toDateMonthly && fromDateQuarterly && toDateQuarterly) {
            console.log("handleFrequencyToggleDisable booleans",(toDateMonthly.diff(fromDateMonthly, 'months') > 2), Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3), toDateQuarterly.diff(fromDateQuarterly, 'months'), (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) > 2))
            if (toDateMonthly.diff(fromDateMonthly, 'months') > 2) {
                setDisableMonthly(false)
            } else {
                setDisableMonthly(true)
            }

            if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) > 2) {
                setDisableQuarterly(false)
            } else {
                setDisableQuarterly(true)
            }
        } else {
            setDisableMonthly(true)
            setDisableQuarterly(true)
        }
        if (toDateMonthly.diff(fromDateMonthly, 'months') <= 2 && frequency !== "Weekly") {
            setPosition("left")
            setFrequency("Weekly")
            props.setSelectedFrequency("Weekly")
            

        } else if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) <= 2 && frequency === "Quarterly") {
            setPosition("center")
                setFrequency("Monthly")
                props.setSelectedFrequency("Monthly")
        }


    }
    const handleFrequency = (event, newOptionSelected) => {
        if (newOptionSelected !== null && newOptionSelected!== undefined) {
            if (newOptionSelected === "left") {
                setPosition("left")
                setFrequency("Weekly")
                props.setSelectedFrequency("Weekly")
            }
            else if (newOptionSelected === "center") {
                setPosition("center")
                setFrequency("Monthly")
                props.setSelectedFrequency("Monthly")
            }
            else {
                setPosition("right")
                setFrequency("Quarterly")
                props.setSelectedFrequency("Quarterly")
            }
        }
        else {
            setPosition("left")
            setFrequency("Weekly")
        }
    };

    React.useEffect(() => {
        handleFrequency()
        // handleFrequencyToggleDisable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        handleFrequencyToggleDisable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new Date(props.fromDate), new Date(props.toDate)])

    const classes = useStyles();

    return (
        <ToggleButtonGroup
            className={classes.toggleContainer}
            value={position}
            exclusive
            onChange={handleFrequency}
            aria-label="text alignment"
        >
            <ToggleButton value="left" aria-label="left aligned" >
                Weekly
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered" disabled={disableMonthly}>
                Monthly
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned" disabled={disableQuarterly}>
                Quarterly
            </ToggleButton>
        </ToggleButtonGroup>
    );
}