import React from "react";
import "./TaskAlert.css";
import TaskBarContainer from "./TaskBarContainer";
import axios from "axios";
import { Paper } from "@material-ui/core";
import { B2PROSPECT_URL } from "../Constant";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";



const TaskAlert = (props) => {
  const userData = useAuthState();
let tenantId = userData.user.tenantId;
  const [taskAlertData, setTaskAlertData] = React.useState([]);
  const getCountApi = async () => {
    var temporaryTaskAlertData = [
      {
        heading: "Tasks",
        tasks: [
          {
            label: "Webform Leads to proccess",
            value: "",
          },
          {
            label: "Accounts to be Enriched",
            value: "",
          },
          {
            label: "Contacts to be Enriched",
            value: "",
          },
        ],
      },
      {
        heading: "Alerts",
        tasks: [
          {
            label: "Accounts without Contact",
            value: "",
          },
          {
            label: "Contacts without Mobile",
            value: "",
          },
          {
            label: "Accounts with less than 2 contacts",
            value: "",
          },
        ],
      },
    ];
    await axios
      .get(
        `${B2PROSPECT_URL}/b2pm/staging/${tenantId}/getCounts`,
        { withCredentials: true }
      )
      .then((webformFill) => {
        // console.log(`%c${JSON.stringify(webformFill.data)}`, 'color: red')
        temporaryTaskAlertData[0].tasks[0].value = webformFill.data.size;
      });
    await axios
      .get(
        `${B2PROSPECT_URL}/b2pm/enrichment/${tenantId}/getCounts`,
        { withCredentials: true }
      )
      .then((enrichment) => {
        // console.log(`%c${JSON.stringify(enrichment.data)}`, 'color: blue')
        temporaryTaskAlertData[0].tasks[1].value = enrichment.data.account;
        temporaryTaskAlertData[0].tasks[2].value = enrichment.data.contact;
      });
    await axios
      .get(
        `${B2PROSPECT_URL}/b2am/analytics/${tenantId}/getCounts`,
        { withCredentials: true }
      )
      .then((analyticsAlert) => {
        // console.log(`%c${JSON.stringify(analyticsAlert.data)}`, 'color: green')
        temporaryTaskAlertData[1].tasks[1].value = analyticsAlert.data.noPhoneContacts; // fixed bug do not change this order on 06-08-2021

        temporaryTaskAlertData[1].tasks[2].value =
          analyticsAlert.data.twoContacts;
        temporaryTaskAlertData[1].tasks[0].value = analyticsAlert.data.noContacts;

      });
    setTaskAlertData(temporaryTaskAlertData);
  };

  React.useEffect(() => {
    getCountApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-align">
      <Paper
        elevation={3}
        className="chart-container"
        style={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {Array.isArray(taskAlertData) && !taskAlertData ? (
          <React.Fragment>Loading...</React.Fragment>
        ) : (
          taskAlertData.map((taskAlertMapper, index) => {
            return <TaskBarContainer taskAlertMapper={taskAlertMapper} reqBody={props.requestBody}/>;
          })
        )}
      </Paper>
    </div>
  );
};

export default TaskAlert;
