import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
    toggleContainer: {
        backgroundColor: theme.palette.primary.background,
        height: "38px",
        margin: theme.spacing(2, 0),
        borderRadius: "4px",
        border: `1px solid ${theme.palette.primary.light}`,
        selected: theme.palette.primary.light,
        marginLeft:"50px"
    },
    root: {
        selected: {
            backgroundColor: "#109CF1"
        }
    }
}));

export default function AccountContactToggler(props) {

//const [type, setType] = React.useState("Account");
    const [position, setPosition] = React.useState("left")

    const handleAccountContactSelector = (event, newOptionSelected) => {
        if (newOptionSelected !== null && newOptionSelected !== undefined) {
            if (newOptionSelected === "left") {
                setPosition("left")
               props.setType("Accounts")
                props.setSelectedType("newaccount")
            }
            else {
                setPosition("right")
                props.setType("Contacts")
                props.setSelectedType("newcontact")
            }
        }
        else {
            setPosition("left")
        }
    };

    React.useEffect(() => {
        handleAccountContactSelector()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles();

    return (
        <ToggleButtonGroup
            className={classes.toggleContainer}
            value={position}
            exclusive
            onChange={handleAccountContactSelector}
            aria-label="text alignment"
        >
            <ToggleButton value="left" aria-label="left aligned">
                Account
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
                Contact
            </ToggleButton>
        </ToggleButtonGroup>
    );
}