import React from 'react';
import './TaskBarContainer.css';
import TaskBar from "./TaskBar";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Typography } from '@material-ui/core';

const TaskBarContainer = (props) => {
    return (
        <div className='task-container'>
            <div className='sub-task-container'>
                <div className='task-heading'>
                    {props.taskAlertMapper.heading === 'Tasks' ? <PlaylistAddCheckIcon /> : <NotificationImportantIcon />}
                    <Typography variant="h6" style={{ paddingLeft: '10px' }}>{props.taskAlertMapper.heading}</Typography>
                </div>
                <div style={{ paddingTop: '10px' }}>
                    {props.taskAlertMapper.tasks.map((task) => {
                        return <TaskBar task={task} reqBody={props.reqBody} filterBody={props.filterBody}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export default TaskBarContainer
