import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Bar2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Attribute from "./Attribute";

ReactFC.fcRoot(FusionCharts, Bar2D, FusionTheme);

export const BarChart = (props) => {
  console.log(props);

  const [chartConfigs, setChartConfigs] = React.useState([]);
  const setAttributeData = () => {
    setChartConfigs({
      type: "scrollbar2d",
      width: "100%",
      height: "408",
      dataFormat: "json",
      dataSource: {
        chart: {          
          plottooltext: "$label: <b>$dataValue</b>",
          palettecolors: "#5D9CEC",
          showValues: "1",
          scrolltoend: "1",
          placeValuesInside: "0",
          theme: "fusion",
        },
        categories: [{category:props.categoryData}],
          dataSet: [{data:props.chartData}]
      },
    });
    console.log(chartConfigs);
  };

  React.useEffect(() => {
    setAttributeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chartData, props.xAxisLabel, props.categoryData]);

  return (
    <div style={{ dispaly: 'flex', flexDirection: 'column', width: '75%' }}>
        <Attribute setAttribute={props.setAttribute} attributeValue={props.attributeValue} />
      {Array.isArray(chartConfigs) && !chartConfigs.length ? <React.Fragment>Loading...</React.Fragment> :
        <ReactFC {...chartConfigs} />}
    </div>
  )
};
