import React from "react";
import './App.css';
import AccountSummaryReport from "./AccountSummaryReport";
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={AccountSummaryReport} ></Route>
          <Route exact path="/filter/:id" component={AccountSummaryReport} ></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
