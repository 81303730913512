import React from "react";
import "./TotalAccountCount.css";
import Typography from "@material-ui/core/Typography";

const TotalAccountCount = (props) => {
  return (
    <div className="total-account-count-container">
      <Typography
        variant="h6"
        component="h6"
        className="total-account-typography"
        style={{ height: "50%", fontWeight:700}}
      >
        Total Account
      </Typography>
      <Typography variant="h4" style={{ fontWeight: "700" }}>
        {props.totalAccountValue}
      </Typography>
    </div>
  );
};

export default TotalAccountCount;
