import React from 'react';
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Bar2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Divider from '@material-ui/core/Divider';
import { useAuthState } from '@bamboobox/b2logincheck';
import { B2PROSPECT_URL, B2FRONTEND_PROSPECT_URL } from "../Constant";
import { BarChart } from "./BarChart";
import TotalAccountCount from "./TotalAccountCount";
import Attribute from "./Attribute";
import { AttributeList } from "./AttributeList";
import attributelist from '../filters/attributelist';
import './TotalAccount.css';
import { Paper } from "@material-ui/core"

ReactFC.fcRoot(FusionCharts, Bar2D, FusionTheme);

const TotalAccount = (props) => {
  const [totalAccountValue, setTotalAccountValue] = React.useState(null)
  const [attributeChart1, setAttributeChart1] = React.useState(null)
  const [attributeChart2, setAttributeChart2] = React.useState(null)
  const [attributeChart3, setAttributeChart3] = React.useState(null)
  const [attributeValue1, setAttributeValue1] = React.useState(AttributeList[0])
  const [attributeValue2, setAttributeValue2] = React.useState(AttributeList[1])
  const [attributeValue3, setAttributeValue3] = React.useState(AttributeList[3])
  const [categoryChart1, setCategoryChart1] = React.useState(null)
  const [categoryChart2, setCategoryChart2] = React.useState(null)
  const [categoryChart3, setCategoryChart3] = React.useState(null)


  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const getTotalAccountChartData = () => {
    getAttributeChart1()
    getAttributeChart2()
    getAttributeChart3()
  }

  const getAttributeChart1 = () => {
    fetch(
      `${B2PROSPECT_URL}/b2am/analytics/${tenantId}/totalaccountsplit?attr1=${attributeValue1.value}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.requestBody),
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        var chartDataArray = []
        let categoryDataArray = []
        var attributeKey = Object.keys(data.listAttributes[0])

        for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {

          console.log(JSON.stringify(data.listAttributes[0][attributeKey]))
          if (data.listAttributes[0][attributeKey][attributeRowValue][0].toString().toLowerCase() !== 'unassigned') {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "includes",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {

              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          else {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "Is Empty",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {
              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          let categoryValObj = {
            "label": "",
            "value": ""
          }
          attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue][1]
          categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue][1]
          categoryDataArray.push(categoryValObj)
          chartDataArray.push(attributeRowValueObject)
        }

        chartDataArray.sort((a, b) => {
          return a.value - b.value;
        });

        categoryDataArray.sort((a, b) => {
          return a.value - b.value;
        })
        setAttributeChart1(chartDataArray)
        setCategoryChart1(categoryDataArray)
      })
  }
  const getAttributeChart2 = () => {
    fetch(
      `${B2PROSPECT_URL}/b2am/analytics/${tenantId}/totalaccountsplit?attr1=${attributeValue2.value}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.requestBody),
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        var chartDataArray = []
        let categoryDataArray = []
        var attributeKey = Object.keys(data.listAttributes[0])

        for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {
          if (data.listAttributes[0][attributeKey][attributeRowValue][0].toString().toLowerCase() !== 'unassigned') {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "includes",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {

              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          else {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "Is Empty",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {
              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          let categoryValObj = {
            "label": "",
            "value": ""
          }
          attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue][1]
          categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue][1]

          categoryDataArray.push(categoryValObj)
          chartDataArray.push(attributeRowValueObject)
        }

        chartDataArray.sort((a, b) => {
          return a.value - b.value;
        });
        categoryDataArray.sort((a, b) => {
          return a.value - b.value;
        })
        setAttributeChart2(chartDataArray)
        setCategoryChart2(categoryDataArray)

      })
  }
  const getAttributeChart3 = () => {
    fetch(
      `${B2PROSPECT_URL}/b2am/analytics/${tenantId}/totalaccountsplit?attr1=${attributeValue3.value}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.requestBody),
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        setTotalAccountValue(data.totalCount)
        var chartDataArray = []
        let categoryDataArray = []
        var attributeKey = Object.keys(data.listAttributes[0])

        for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {
          if (data.listAttributes[0][attributeKey][attributeRowValue][0].toString().toLowerCase() !== 'unassigned') {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "includes",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {

              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          else {
            let dynamicFilterObject = [{
              "attribute": "",
              "relation": "Is Empty",
              "values": []
            }]
            let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
            dynamicFilterObject[0].attribute = AttributeLabel.label
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue][0]]
            // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
            // alert(JSON.stringify(props.requestBody))

            if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
              props.filterBody.forEach(reqBody => {
                dynamicFilterObject.push(reqBody)
              })
            }
            var attributeRowValueObject = {
              "label": "",
              "value": "",
              "link": `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/account_management/${escape(JSON.stringify(props.filterBody))}`
            }
          }
          let categoryValObj = {
            "label": "",
            "value": ""
          }
          attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue][1]
          categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue][0]
          categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue][1]
          categoryDataArray.push(categoryValObj)
          chartDataArray.push(attributeRowValueObject)
        }

        chartDataArray.sort((a, b) => {
          return a.value - b.value;
        });
        categoryDataArray.sort((a, b) => {
          return a.value - b.value;
        })
        
        setAttributeChart3(chartDataArray)
        setCategoryChart3(categoryDataArray)

      })
  }

  React.useEffect(() => {
    if (props.icpSelected !== null) {
      getTotalAccountChartData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestBody, props.icpSelected])

  React.useEffect(() => {
    getAttributeChart1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue1])

  React.useEffect(() => {
    getAttributeChart2()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue2])

  React.useEffect(() => {
    getAttributeChart3()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue3])

  return (
    <div className='container-align'>
      <Paper
        elevation={3}
        className='chart-container'>
        {attributeChart1 === null || attributeChart2 === null || attributeChart3 === null || categoryChart1 === null || categoryChart2 === null || categoryChart3 === null ? <React.Fragment>Loading...</React.Fragment> :
          // <div style={{ display: 'flex', flexDirection: 'column' }}>
          //   <Attribute setAttributeSelectedList={setAttributeSelectedList} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='funnel-ageing-container' style={{ display: 'flex', flexBasis: "25%", minWidth: '50px' }}>
              <TotalAccountCount totalAccountValue={totalAccountValue} />
              <Divider variant="middle" orientation="vertical" flexItem />
              <BarChart chartData={attributeChart1} setAttribute={setAttributeValue1} attributeValue={attributeValue1} categoryData={categoryChart1}/>
              <Divider variant="middle" orientation="vertical" flexItem />
              <BarChart chartData={attributeChart2} setAttribute={setAttributeValue2} attributeValue={attributeValue2} categoryData={categoryChart2} />
              <Divider variant="middle" orientation="vertical" flexItem />
              <BarChart chartData={attributeChart3} setAttribute={setAttributeValue3} attributeValue={attributeValue3} categoryData={categoryChart3}/>
            </div>
            {/* </div> */}
          </div>}
      </Paper>
    </div>
  )
}

export default TotalAccount
