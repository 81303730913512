export const AttributeList = [
    {
    label: "Account Classification",
    value: "company_classification",
    },
    { 
    label: "Industry",
    value: "company_primary_industry_category",
    },
    {
    label: "Sub Industry",
    value: "company_sub_industry_category",
    },
    {
    label: "Capillary Geography",
    value: "crm_account_geography",
    },
    {
    label: "Account Region",
    value: "company_account_region",
    },
    {
    label: "Company Type",
    value: "company_type",
    },
    {
    label: "Company Hierarchy",
    value: "company_hierarchy",
    },
    {
    label: "Business Format",
    value: "crm_business_format",
    } 
] ;
    
    // export const DEFAULT_ATTRIBUTES_FOR_SUPERUSER = [
    // "Industry",  
    // "Account Classification",
    // "Geography",
    // ];
    
    // export const DEFAULT_ATTRIBUTES_FOR_OTHER_USER = [
    // "Industry",
    // "Account Classification",
    // "Account Type",
    // ];
