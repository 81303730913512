import React, { useState } from "react";
import { FilterContext } from "../index";
import AttributeItem from "../attributeitem";
import { Typography } from "@material-ui/core";

function AttributeList(props) {
  const { attributeList, getFilterData } = React.useContext(FilterContext);

  return (
    <div>
      {/* style="display: flex; justify-content: space-between; align-items: center; padding: 20px;" */}
      <div style={{display:'flex', justifyContent: 'space-between',alignItems:'center',marginTop:"12px"}}>
        <Typography variant="subtitle2" style={{width:150 }}>Attribute</Typography>
        <Typography variant="subtitle2" style={{width:150}}>Relation</Typography>
        <Typography variant="subtitle2" style={{width:200}}>Values</Typography>
        <Typography variant="subtitle2" style={{position:"relative",left:"-48px"}}>Actions</Typography>
      </div>
      <div>
      { 
        attributeList?.map((attribute, index) => (
          <div key={attribute?.attrId}>
            <AttributeItem attributeData={attribute} elementIndex={index} />
          </div>  
        ))
      }
    </div>
    </div>
  );
}

export default React.memo(AttributeList);
