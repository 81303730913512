import React from "react";
import { AttributeList } from "./AttributeList";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));


const Attribute = (props) => {

  const classes = useStyles();
  console.log(props.attributeValue)
  const [attribute, setAttribute] = React.useState(props.attributeValue.value);

  const handleChange = (event) => {
    let AttributeLabel = AttributeList.find(o => o.value === event.target.value);
    setAttribute(event.target.value)
    props.setAttribute(AttributeLabel)
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {/* <InputLabel id="demo-simple-select-outlined-label"></InputLabel> */}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={attribute}
        onChange={handleChange}
        
      >
        {AttributeList.map((attrList) => (
          <MenuItem value={attrList.value}>{attrList.label}</MenuItem>
        ))
        }
      </Select>
    </FormControl>
  );
};

export default Attribute;
