

export function saveFilters(mode,attributeData,filterName,tenantId,ui) {    
    const bodyData = {
        "tenantId" : `${tenantId}`,
        "filterName" : filterName,
        "page" : `${ui}`
    };
    const attributeArray = attributeData.map((object) => {
        const obj = {...object};
        delete obj.isValid;
        delete obj.index;
        return {...obj,...bodyData};
    })    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({mode:mode , data: attributeArray}),
        credentials: "include"
    };

    return fetch(`${window._env_.FILTER_URL}/save-filter?tenantId=${tenantId}&&ui=${ui}`, requestOptions )
        .then(response => response.json())
        .catch(err => {
            console.error(err);
        })        
}

export function getFilterNames(tenantId,ui) {
    return fetch(`${window._env_.FILTER_URL}/get-filter?tenantId=${tenantId}&&ui=${ui}` , { credentials: "include"})
      .then(res => res.json())
      .catch(err => {
        console.error(err);
      })   
}

export function getIcpListName(tenantId) {
  return fetch(`${window._env_.ICP_URL}/scoredefinition/${tenantId}`, { credentials: "include" })
    .then(res => res.json())
    .catch(err => {
      console.error(err);
    })   
}

export function getFilterDataByName(filterName,tenantId,ui) {
    return fetch(`${window._env_.FILTER_URL}/get-filter-byname?filterName=${filterName}&&tenantId=${tenantId}&&ui=${ui}`, { credentials: "include" })
    .then(res => res.json())
    .catch(err => {
      console.error(err);
    }) 
  }

  export function deleteFilters(name,tenantId,ui) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
         filterName: name,
         tenantId,
         page: ui
      }),
      credentials: "include" 
    };
    return fetch(`${window._env_.FILTER_URL}/delete-filter`, requestOptions)
      .then(response => response.json())
      .catch(err => {
          console.error(err);
      })    
  }