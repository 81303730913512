import React from "react";
import "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./TimeSpanComponent.css";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
function convertDateRangeToFormattedString(oldDate, currentDate) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dateString = `${oldDate.getDate()} ${monthArray[oldDate.getMonth()]
    }, ${oldDate.getFullYear().toString().substring(2, 4)} - ${currentDate.getDate()} ${monthArray[currentDate.getMonth()]
    }, ${currentDate.getFullYear().toString().substring(2, 4)}`;
  console.log(
    "convertDateRangeToFormattedString",
    oldDate,
    currentDate,
    dateString
  );
  return dateString;
}

function TimeSpanComponent(props) {
  var threeWeeksOldDate = new Date();
  threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

  const frequencyValues = ["Weeks", "Months", "Quarters", "Years"];

  const [open, setOpen] = React.useState(false);

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [oldDate, setOldDate] = React.useState(threeWeeksOldDate);
  const [displayedDate, setDisplayedDate] = React.useState(
    convertDateRangeToFormattedString(oldDate, currentDate)
  );

  const [fromDateMax, setFromDateMax] = React.useState(new Date());
  const [fromDateMin, setFromDateMin] = React.useState(undefined);
  const [toDateMax, setToDateMax] = React.useState(new Date());
  const [toDateMin, setToDateMin] = React.useState(undefined);

  const [radioBtnValue, setRadioBtnValue] = React.useState("fixed");

  const [numberFieldvalue, setNumberFieldvalue] = React.useState("3");
  const [intervalValue, setIntervalValue] = React.useState("Weeks");
  const [prevOldDate, setPrevOldDate] = React.useState(oldDate)
  const [prevCurrentDate, setPrevCurrentDate] = React.useState(currentDate)
  const [prevIntervalValue, setPrevIntervalValue] = React.useState(intervalValue)
  const [prevNumberFieldValue, setPrevNumberFieldValue] = React.useState(numberFieldvalue);

  let frequencySelected = props?.frequencySelected;
  let setFromDate = props?.setFromDate;
  let setToDate = props?.setToDate;

  const handleNumberChange = (event) => {
    // console.log("changed number to ", event?.target?.value)

    // if (event?.target?.value < 0) event.target.value = 0;

    // if (intervalValue === "Days" && event?.target?.value > 366){
    //     event.target.value = 366;
    // }
    // if (intervalValue === "Weeks" && event?.target?.value > 53){
    //     event.target.value = 53;
    // }
    // if (intervalValue === "Months" && event?.target?.value > 12){
    //     event.target.value = 12;
    // }
    // if (intervalValue === "Quarters" && event?.target?.value > 4){
    //     event.target.value = 4;
    // }
    // if (intervalValue === "Years" && event.target.value > 1){
    //     event.target.value = 1;
    // }

    let todayDate = new Date();
    setCurrentDate(new Date());

    if (event?.target?.value < 0) event.target.value = 0;

    if (intervalValue === "Days") {
      todayDate.setDate(todayDate.getDate() - parseInt(numberFieldvalue));
    }
    if (intervalValue === "Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 0) * 7)
      );
    }
    if (intervalValue === "Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt(event.target.value || 0)
      );
    }
    if (intervalValue === "Quarters") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((event.target.value || 0) * 3)
      );
    }
    if (intervalValue === "Years") {
      todayDate.setFullYear(
        todayDate.getFullYear() - parseInt(event.target.value || 0)
      );
    }

    console.log(
      "setting number to ",
      event?.target?.value,
      parseInt(event.target.value),
      intervalValue,
      todayDate
    );
    setOldDate(new Date(todayDate));

    if (numberFieldvalue !== event?.target?.value) {
      setPrevNumberFieldValue(numberFieldvalue)
      setNumberFieldvalue(event.target.value);
    }
  };

  const handleIntervalChange = (event) => {
    // console.log("changed interval to ", event?.target?.value)

    // if (event?.target?.value === "Days" && numberFieldvalue > 366) { console.log("Days selected setting number to 366");setNumberFieldvalue("366");}
    // if (event?.target?.value === "Weeks" && numberFieldvalue > 53) {console.log("Weeks selected setting number to 53");setNumberFieldvalue("53");}
    // if (event?.target?.value === "Months" && numberFieldvalue > 12) {console.log("Months selected setting number to 12");setNumberFieldvalue("12");}
    // if (event?.target?.value === "Quarters" && numberFieldvalue > 4){ console.log("Quarters selected setting number to 4");setNumberFieldvalue("4");}
    // if (event?.target?.value === "Years" && numberFieldvalue > 1) {console.log("Years selected setting number to 1");setNumberFieldvalue("1");}

    let todayDate = new Date();
    setCurrentDate(new Date());

    if (event.target?.value === "Days") {
      todayDate.setDate(todayDate.getDate() - parseInt(numberFieldvalue));
    }
    if (event.target?.value === "Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((numberFieldvalue || 0) * 7)
      );
    }
    if (event.target?.value === "Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt(numberFieldvalue || 0)
      );
    }
    if (event.target?.value === "Quarters") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((numberFieldvalue || 0) * 3)
      );
    }
    if (event.target?.value === "Years") {
      let tem = todayDate;
      console.log(
        "handleIntervalChange - In years - before",
        tem,
        tem.getFullYear() - parseInt(event.target.value || 0)
      );
      todayDate.setFullYear(
        todayDate.getFullYear() - parseInt(numberFieldvalue || 0)
      );
      console.log(
        "handleIntervalChange - In years - after",
        todayDate,
        new Date(todayDate)
      );
    }

    setOldDate(new Date(todayDate));

    console.log("setting interval to ", event?.target?.value);

    if (intervalValue !== event?.target?.value){
      setPrevIntervalValue(intervalValue);
      setIntervalValue(event.target.value);
    }
  };

  const handleRadioBtnChange = (event) => {
    console.log("changed radio button to ", event?.target?.value);
    setRadioBtnValue(event?.target?.value);
    let e = { target: { value: "" } }
    e.target.value = intervalValue;
    handleIntervalChange(e);
  };

  const handleNormalBtnClick = () => {
    if (open) {
      setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
      var fromDateValue = new Date(oldDate)
      fromDateValue.setUTCDate(fromDateValue.getDate())
      fromDateValue.setUTCHours(0, 0, 0, 0)

      var toDateValue = new Date(currentDate)
      toDateValue.setUTCDate(toDateValue.getDate())
      toDateValue.setUTCHours(0, 0, 0, 0)
      setFromDate(fromDateValue);
      setToDate(toDateValue);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDateFromChange = (value) => {
    let tempDate = new Date(value);
    setToDateMin(tempDate.setDate(tempDate.getDate() + 1));
    setPrevOldDate(oldDate)
    setOldDate(new Date(value));
    console.log(
      "handleDateFromChange ",
      value,
      new Date(value),
      tempDate,
      toDateMin
    );
  };

  const handleDateToChange = (value) => {
    let tempDate = new Date(value);

    setFromDateMax(tempDate.setDate(tempDate.getDate() - 1));
    setPrevCurrentDate(currentDate);
    setCurrentDate(new Date(value));
    console.log(
      "handleDateToChange ",
      value,
      new Date(value),
      tempDate,
      fromDateMin
    );
  };

  const handleClickAway = () => {
    console.log(
      "handleClickAway",
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    setOpen(false);

    setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    var fromDateValue = new Date(Date.UTC(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate()))
    // fromDateValue.setUTCDate(fromDateValue.getDate())
    // fromDateValue.setUTCHours(0, 0, 0, 0)

    var toDateValue = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))
    // toDateValue.setUTCDate(toDateValue.getDate())
    // toDateValue.setUTCHours(0, 0, 0, 0)
    setFromDate(fromDateValue);
    setToDate(toDateValue);
  };

  const handleCancelBtn = () => {
    setOpen(false)
    setOldDate(prevOldDate);
    setCurrentDate(prevCurrentDate)
    setNumberFieldvalue(prevNumberFieldValue);
    setIntervalValue(prevIntervalValue)
  }
  
  return (
    <MuiThemeProvider theme={UItheme}>
      <ClickAwayListener onClickAway={() => { }}>
        <div className="dropdownLayout">
          <Button
            variant="outlined"
            onClick={handleNormalBtnClick}
            style={{ height: "42px", width: "200px", textTransform: "none" }}
            size="medium"
          >
            {displayedDate}
          </Button>

          {open ? (
            <Paper className="act_like_dropdown">
              <RadioGroup
                value={radioBtnValue}
                onChange={handleRadioBtnChange}
                style={{ marginTop: "18px" }}
              >
                <FormControlLabel
                  value="fixed"
                  control={<Radio />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      Past
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                          pattern: "[0-9]*"
                        }}
                        type="number"
                        onChange={handleNumberChange}
                        disabled={radioBtnValue !== "fixed"}
                        value={numberFieldvalue}
                        style={{ marginLeft: "20px", width: "40px" }}
                      />
                      <Select
                        value={intervalValue}
                        renderValue={(value) => <label>{value}</label>}
                        onChange={handleIntervalChange}
                        disabled={radioBtnValue !== "fixed"}
                        style={{ paddingTop: '2px' }}
                      >
                        {frequencyValues.map((data, index) => {
                          return (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  }
                />

                <FormControlLabel
                  value="custom"
                  style={{
                    alignItems: "flex-start",
                    paddingTop: "20px",
                  }}
                  control={<Radio />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        Custom Range
                      </Typography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        From:
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={radioBtnValue === "fixed"}
                          minDate={fromDateMin}
                          maxDate={fromDateMax}
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="DD-MM-YYYY"
                          value={oldDate}
                          onChange={handleDateFromChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <br />
                        To:
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={radioBtnValue === "fixed"}
                          minDate={toDateMin}
                          maxDate={toDateMax}
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="DD-MM-YYYY"
                          value={currentDate}
                          onChange={handleDateToChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  }
                />
              </RadioGroup>
              <div style={{ display: "flex", marginTop: "15px", alignContent: "center", justifyContent: "flex-end" }}>
                <Button variant="outlined" color="primary" onClick={handleCancelBtn} style={{ marginRight: "24px" }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickAway}>
                  Apply
                </Button>
              </div>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </MuiThemeProvider>
  );
}

export default TimeSpanComponent;
