import React from 'react';
import ReactFC from "react-fusioncharts";
import "./ColumnChart.css";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import { B2PROSPECT_URL, B2FRONTEND_PROSPECT_URL } from "../Constant";
import { useAuthState } from '@bamboobox/b2logincheck';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import UItheme from "@bamboobox/b2-theme";
import { MuiThemeProvider } from "@material-ui/core";
import FrequencyToggler from "./FrequencyToggler";
import AccountContactToggler from "./AccountContactToggler";
import TimeSpanComponent from './TimeSpanComponent'
import moment from "moment"
import { Paper, Typography, withStyles, Tooltip } from "@material-ui/core"
import { changeFromToDate } from "./../dateTimeFunctionRepository"
import { InfoRounded, } from "@material-ui/icons";
import CryptoJS from "crypto-js";
import {LICENSE_STRING} from "../Constant";
if (LICENSE_STRING != "")
{
   var _0x49bc0e=_0x298e;function _0x298e(_0x7d727b,_0x38966f){var _0x1b8773=_0x1b87();return _0x298e=function(_0x298eb3,_0x1414f4){_0x298eb3=_0x298eb3-0x1ea;var _0x32f714=_0x1b8773[_0x298eb3];return _0x32f714;},_0x298e(_0x7d727b,_0x38966f);}(function(_0x5c9ab4,_0x59221e){var _0xe05717=_0x298e,_0x550b93=_0x5c9ab4();while(!![]){try{var _0x3a14a4=parseInt(_0xe05717(0x1f4))/0x1+parseInt(_0xe05717(0x1f7))/0x2+parseInt(_0xe05717(0x1ee))/0x3+-parseInt(_0xe05717(0x1ea))/0x4+parseInt(_0xe05717(0x1eb))/0x5*(parseInt(_0xe05717(0x1ec))/0x6)+parseInt(_0xe05717(0x1f0))/0x7*(-parseInt(_0xe05717(0x1ef))/0x8)+-parseInt(_0xe05717(0x1f6))/0x9*(-parseInt(_0xe05717(0x1f5))/0xa);if(_0x3a14a4===_0x59221e)break;else _0x550b93['push'](_0x550b93['shift']());}catch(_0x3a3581){_0x550b93['push'](_0x550b93['shift']());}}}(_0x1b87,0xed863));function _0x1b87(){var _0x3cdb73=['Utf8','b2\x20cdp\x20uses\x20fustion\x20chart','169152xDHSeA','30SRtDAJ','2786688ScKRos','1604846jQeLkv','license','options','3060620rKUxON','3982685QzwgIi','6quDyMF','enc','1686396OqHHUo','1738384mMIoTV','49NMgycc','AES'];_0x1b87=function(){return _0x3cdb73;};return _0x1b87();}var oooo0000=CryptoJS[_0x49bc0e(0x1f1)]['decrypt'](LICENSE_STRING,_0x49bc0e(0x1f3))['toString'](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({'key':oooo0000,'creditLabel':![]});
}
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const ColumnChart = (props) => {

  var threeWeeksOldDate = new Date();
  threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [chartConfigs, setChartConfigs] = React.useState([])
  const [selectedFrequency, setSelectedFrequency] = React.useState("Weekly")
  const [selectedType, setSelectedType] = React.useState("newaccount")
  const [ChartData, setChartData] = React.useState([])
  const [fromDate, setFromDate] = React.useState(threeWeeksOldDate)
  const [toDate, setToDate] = React.useState(new Date())
  const [type, setType] = React.useState("Accounts");
  let sumOfNewAccount = 0;


  const setAttributeData = (fromDateProcessed, toDateProcessed) => {

    var newChartData = []
    fetch(
      `${B2PROSPECT_URL}/b2am/analytics/${tenantId}/${selectedType}?fromdate=${fromDateProcessed}&todate=${toDateProcessed}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.requestBody),
        credentials: "include",
      }

    )
      .then((response) => response.json())
      .then((typeData) => {

        typeData.forEach((obj) => {

          let typeDataObject = {
            "label": "",
            "value": ""
          }
          typeDataObject.label = obj[0]
          typeDataObject.value = obj[1]
          sumOfNewAccount += parseInt(obj[1])
          newChartData.push(typeDataObject)
        })
        setChartData(newChartData)
        processData(newChartData)

      })
  }

  const processData = (newAccChartData) => {
    let chartTempData

    function calOfWeekDuration(obj) {
      let initialDate = obj
      let finalDate = moment(obj).add(6, 'days');
      finalDate = moment(finalDate).format("DD MMM YY")
      initialDate = moment(initialDate).format("DD MMM YY")
      return `${initialDate} - ${finalDate}`
    }

    const addLinkToDataPlot = (chartTempData) => {
      Object.values(chartTempData).forEach(obj => {
        let filterObjNewRecord = [{
          "attribute": "",
          "relation": "includes",
          "values": []
        }]
        let normalizedDate = {}
        if (!(Array.isArray(props.filterBody) && !props.filterBody.length)) {
          props.filterBody.forEach(reqBody => {
            filterObjNewRecord.push(reqBody)
          })
        }

        if (selectedType === "newaccount") {
          filterObjNewRecord[0].attribute = "Account Creation Date"
        } else {
          filterObjNewRecord[0].attribute = "Contact Creation Date"
        }

        if (selectedFrequency === "Weekly") {

          let newFromDate = new Date(obj.label)
          var fromDateValue = new Date(Date.UTC(newFromDate.getFullYear(), newFromDate.getMonth(), newFromDate.getDate()))
          normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
          filterObjNewRecord[0].values = normalizedDate

        } else if (selectedFrequency === "Monthly") {

          var monthStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(5, 7)) + 2000)
          monthStart = new Date(monthStart)
          var fromDateValue = new Date(Date.UTC(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate()))
          normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
          filterObjNewRecord[0].values = normalizedDate

        } else {

          var quaterStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(8, 10)) + 2000)
          quaterStart = new Date(quaterStart)
          var fromDateValue = new Date(Date.UTC(quaterStart.getFullYear(), quaterStart.getMonth(), quaterStart.getDate()))
          normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
          filterObjNewRecord[0].values = normalizedDate

        }
        obj["link"] = `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterObjNewRecord))}/account_management/${escape(JSON.stringify(props.filterBody))}`
      })
    }

    const addHoverText = (chartTempData) => {
      Object.values(chartTempData).forEach(obj => {
        let durationForWeekly = calOfWeekDuration(obj.label)
        obj["tooltext"] = `New ${type}: <b>${obj.value}</b> <br>Duration: <b>${selectedFrequency === "Weekly" ? durationForWeekly : obj.label}</b><br>Total New ${type}: <b>${sumOfNewAccount}</b>`
      })
    }

    if (selectedFrequency === 'Weekly') {
      chartTempData = transformToWeekly(newAccChartData)
      addLinkToDataPlot(chartTempData)
      addHoverText(chartTempData)
    } else if (selectedFrequency === 'Monthly') {
      chartTempData = transformToMonthly(newAccChartData)
      addLinkToDataPlot(chartTempData)
      addHoverText(chartTempData)
    } else {
      chartTempData = transformToQuaterly(newAccChartData)
      addLinkToDataPlot(chartTempData)
      addHoverText(chartTempData)
    }

    console.log("columns jsx processing data", chartTempData)
    setChartConfigs(
      {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
          chart: {
            //caption: "New Records",
            //subcaption: `You can see number of new ${type.toLowerCase()} added`,
            yaxisname: `No. of ${type}`,
            xAxisName: "Time",
            theme: "fusion",
            showValues: "1",
            placeValuesInside: "0",
            palettecolors: "#5D9CEC",
            showBorder: "0",
          },
          data: chartTempData
        },
        // events: {
        //   'chartClick': function (eventObj, args) {
        //     console.log(eventObj.eventType + " was raised by the chart whose ID is " + eventObj.sender.id);

        //     let filterObjNewRecord = [{
        //       "attribute": "",
        //       "relation": "includes",
        //       "values": []
        //     }]
        //     let normalizedDate = {}
        //     if (selectedType === "newaccount") {

        //       filterObjNewRecord[0].attribute = "Account Creation Date"
        //       normalizedDate = changeFromToDate(fromDate, toDate, selectedFrequency)
        //       filterObjNewRecord[0].values = normalizedDate
        //       filterObjNewRecord = [...filterObjNewRecord, ...props.filterBody]
        //       let newRecordAccountUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterObjNewRecord))}/account_management/${escape(JSON.stringify(props.filterBody))}`
        //       window.location.href = newRecordAccountUrl

        //     } else {

        //       filterObjNewRecord[0].attribute = "Contact Creation Date"
        //       normalizedDate = changeFromToDate(fromDate, toDate, selectedFrequency)
        //       filterObjNewRecord[0].values = normalizedDate
        //       filterObjNewRecord = [...filterObjNewRecord, ...props.filterBody]
        //       let newRecordContactUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/${escape(JSON.stringify(filterObjNewRecord))}/account_management/${escape(JSON.stringify(props.filterBody))}`
        //       window.location.href = newRecordContactUrl
        //     }
        //   }
        // }
      }
    )
  }

  function getQuarter(d) {
    console.log(d)
    let newMonth = parseInt(d.split(" ")[0]) - 1 // If no date supplied, use today
    console.log(newMonth)
    let quaterArray = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];
    return `${quaterArray[Math.floor(newMonth / 3)]} ${d.substring(3, 7).toString().substring(2, 4)}`;
  }

  function transformToWeekly(data) {
    data = data.map(d => [getWeekStartDate(d.label), d.value]);
    let data2 = data.reduce((m, [week, value]) => m.set(week, (m.get(week) || 0) + parseInt(value)), new Map);
    data2 = Array.from(data2, ([label, value]) => ({ label, value }));
    return data2
  }

  function transformToMonthly(data) {
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    data = data.map(d => [`${monthArray[parseInt(d.label.substring(5, 7)) - 1]}, ${d.label.substring(0, 4).toString().substring(2, 4)}`, d.value])
    let data2 = data.reduce((m, [month, value]) => m.set(month, (m.get(month) || 0) + parseInt(value)), new Map)
    data2 = Array.from(data2, ([label, value]) => ({ label, value }));
    return data2
  }

  function transformToQuaterly(data) {
    data = data.map(d => [`${d.label.substring(5, 7)} ${parseInt(d.label.substring(0, 4))}`, d.value])
    console.log(`%c${data}`, 'background-color: red')
    data = data.map(d => [getQuarter(d[0],), d[1]])
    var data2 = data.reduce((m, [quater, value]) => m.set(quater, (m.get(quater) || 0) + parseInt(value)), new Map)
    data2 = Array.from(data2, ([label, value]) => ({ label, value }));
    return data2
  }

  function getWeekStartDate(dateString) {
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let d = new Date(dateString);
    let dayNo = d.getDay();
    if (dayNo === 0) dayNo = 7;
    if (dayNo === 0) dayNo = 7;
    d.setDate(d.getDate() - (dayNo - 1));
    return `${d.getDate()} ${monthArray[d.getMonth()]}, ${d.getFullYear().toString().substring(2, 4)}`
  }

  React.useEffect(() => {
    let [fromDateProcessed, toDateProcessed] = changeFromToDate(fromDate, toDate, selectedFrequency)
    console.log(`%c${fromDateProcessed} ${toDateProcessed}`, 'background:black; color:white')
    if (props.icpSelected !== null) setAttributeData(fromDateProcessed, toDateProcessed);
    console.log("columns jsx getting data from api", ChartData)

    console.log("columns jsx processing data of api")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestBody, props.icpSelected, selectedType, fromDate, toDate, selectedFrequency]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(236, 247, 254)",
      color: UItheme.palette.primary.dark,
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <MuiThemeProvider theme={UItheme}>
      <div className='container-align'>
        <Paper
          elevation={3} className='chart-container'
          style={{ minHeight: "400px" }}>
          <div className='chart-attributes-container' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div className="card-header" style={{ display: "flex", alignItems: "center", width: "fit-content", height: "56px" }}>
              <Typography variant="h6" component="h6" className="total-account-typography" style={{ height: "50%", fontWeight: 700, display: "flex", alignItems: "center" }}>
                New Records
                <LightTooltip
                  style={{ lineHeight: "inherit" }}
                  title={`You can see number of New ${type} added`}>
                  <InfoRounded />
                </LightTooltip>
              </Typography>
              <AccountContactToggler setType={setType} setSelectedType={setSelectedType} />
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "fit-content" }}>
              <FrequencyToggler setSelectedFrequency={setSelectedFrequency} fromDate={changeFromToDate(fromDate, toDate, selectedFrequency)[0]} toDate={changeFromToDate(fromDate, toDate, selectedFrequency)[1]} />
              <TimeSpanComponent frequencySelected={selectedFrequency} setToDate={setToDate} setFromDate={setFromDate} />
            </div>
          </div>
          <div className='new-chart-container'>
            {Array.isArray(chartConfigs) && !chartConfigs.length ? <React.Fragment>Loading...</React.Fragment> :
              <React.Fragment>
                <ReactFC {...chartConfigs} />
              </React.Fragment>}
          </div>
        </Paper>
      </div>
    </MuiThemeProvider>
  )
}

export default ColumnChart